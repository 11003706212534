import {createReducer, on} from '@ngrx/store';
import { CsmActions } from './csm.actions';
import { CsmAccountsState } from './csm.state';
import {areObjectsEqual} from '@core/helpers/fn/are-objects-equal.fn';

export const initialState: CsmAccountsState = {
	isLoading: false,
	isLoaded: false,
	filter: null,
	csmAccounts: null,
	error: null,
};

export const csmAccountsReducer = createReducer(
	initialState,
	on(CsmActions.getCsmAccounts, (state, {params}) => ({
		...state,
		isLoading: !areObjectsEqual(state.filter, params) || !state.isLoaded,
	})),
	on(CsmActions.getCsmAccountsSuccess, (state, {csmAccounts, params}) => ({
		...state,
		csmAccounts,
		filter: params,
		isLoading: false,
		isLoaded: true,
	})),
	on(CsmActions.getCsmAccountsFailure, (state, {error}) => ({
		...state,
		isLoading: false,
		error,
	})),
);
