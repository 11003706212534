import {createAction, props} from '@ngrx/store';
import { CsmViewAccount } from '@shared/modules/accounts/types/account';

export namespace CsmActions {
	const prefix = '[CSM accounts]';

	export const getCsmAccounts = createAction(
		`${prefix} Get CSM accounts`,
		props<{params: any}>()
	);
	export const getCsmAccountsSuccess = createAction(
		`${getCsmAccounts.type} success`,
		props<{csmAccounts: CsmViewAccount[]; params: any}>()
	);

	export const getCsmAccountsFailure = createAction(
		`${getCsmAccounts.type} failure`,
		props<{error: any}>()
	);
}
