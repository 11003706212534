import {createSelector} from '@ngrx/store';
import {AppState} from 'src/app/store/app.state';

export const selectFeature = (state: AppState) => state.csmAccounts;

export const isLoadingSelector = createSelector(
	selectFeature,
	state => state.isLoading
);

export const isLoadedSelector = createSelector(
	selectFeature,
	state => state.isLoaded
);

export const filterSelector = createSelector(
	selectFeature,
	state => state.filter
);

export const csmAccountSelector = createSelector(
	selectFeature,
	state => state.csmAccounts
);

export const errorSelector = createSelector(
	selectFeature,
	state => state.error
);
