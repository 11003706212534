import {NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslatePipe} from '@shared/pipes';

@Component({
	selector: 'green-empty',
	standalone: true,
	imports: [NgIf, MatIconModule, TranslatePipe],
	template: `
		@if (showMessage) {
			<span
				class="legend text-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
			>
				{{ 'VIEWS.USER_BOARD.LIBRARY.UPLOAD_FILES' | translate }}
				<mat-icon>more_vert</mat-icon>
				{{ 'VIEWS.USER_BOARD.LIBRARY.UPLOAD_FILES_SUIT' | translate }}
			</span>
		}`,
})
export class EmptyComponent {
	@Input() showMessage?: boolean;
}
