import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {APIResponse} from '@core/helpers/types/api-response';
import {environment} from '@env/environment';
import {CsmViewAccount} from '@shared/modules/accounts/types/account';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CsmService {
	constructor(private http: HttpClient) {}

	getLastAccountsForCsm = (params?: any): Observable<CsmViewAccount[]> =>
		this.http
			.get<APIResponse<CsmViewAccount[]>>(
				environment.root_url + '/historys/accounts',
				{params}
			)
			.pipe(map(res => res.data));
}
