<button
	mat-button
	color="primary"
	(click)="matSelectRef.open()"
	[matBadge]="optionsControl.value?.length"
	[matBadgeHidden]="optionsControl.value?.length === 0"
	[disabled]="disabled">
	<span class="flex items-center">
		{{ action | translate }}
		<mat-icon>keyboard_arrow_down</mat-icon>
	</span>
</button>

<mat-select
	class="option-selector !w-initial"
	[formControl]="optionsControl"
	[disableOptionCentering]="true"
	multiple
	#matSelectRef>

	@if (!matSelectRef.empty) {
		<span
			mat-menu-item
			(click)="clear()"
			class="flex justify-center"
		>
			<span class="flex items-center justify-center">
				<mat-icon inline color="warn">settings_backup_restore</mat-icon>
				{{ 'BUTTONS.RESET' | translate }}
			</span>
		</span>
	}
	@for (option of options; track option.key) {
		<mat-option [value]="option.key">
			{{ option.translateKey | translate }}
		</mat-option>
	}
</mat-select>
