<!-- MENU -->
 @if (mode === 'menu') {
	<div>
		<button
			[matBadge]="(values ?? []).length"
			[matBadgeHidden]="(values ?? []).length === 0 || !badge"
			mat-button
			color="primary"
			[matMenuTriggerFor]="autocompleteMenu">
			<span class="flex items-center">
				<!-- {{ 'SHARED.TAGS.LABEL' | translate }}
				<mat-icon>keyboard_arrow_down</mat-icon> -->
				<mat-icon
				ngStyle="{'color':noLabel ? '#080074' : 'grey' }"
					style="font-size: 1.5em; font-weight: bold; margin: -7px"
					>turned_in</mat-icon
				>

				@if (!noLabel) {
					<span ngStyle="{'color':noLabel ? '#080074' : 'grey' }" style="font-size: 1.1em">
						&nbsp; {{ 'SHARED.TAGS.LABEL' | translate }}
					</span>
				}
			</span>
				
		</button>
		<mat-menu
			class="auto-complete-menu"
			#autocompleteMenu="matMenu"
			yPosition="below"
			xPosition="after">
			<div class="input autoComplete">
				<green-input-auto-complete
					[listValues]="listValues"
					[values]="values ?? []"
					[mode]="mode"
					[type]="type"
					(onChangedValues)="valuesChanged($event)" />
			</div>
		</mat-menu>
	</div>
 }


<!-- INPUT -->
 @if (mode === 'input') {
	<green-input-auto-complete
	class="input-mode"
	[values]="values ?? []"
	[mode]="mode"
	[listValues]="listValues"
	[type]="type" />
 }


<!-- LIST -->
 @if (mode === 'list') {
	<div>

		@if (!editView) {
			<div class="read-only-view flex items-center justify-center gap-3">

				@if (values) {
					<mat-chip-listbox aria-label="Dog selection">

						@for (value of values.slice(0, chipLimit); track value) {
							<mat-chip-option>
								<green-truncated-text class="text-xs" [value]="value" [maxLength]="7" />
							</mat-chip-option>
						}						
						@if (hiddenTags > 0) {
							<mat-chip-option
								[matTooltip]="values.slice(chipLimit).join(' | ')"
								selected="false"
								color="primary">
								+ {{ hiddenTags }}
							</mat-chip-option>
						}	
					</mat-chip-listbox>
				}			
				@if (!values || values.length < 1) {
					<span class="tags-empty legend">
						{{ 'SHARED.TAGS.EMPTY' | translate }}
					</span>
				}
				@if (type !== 'readonly') {
					<a
						mat-icon-button
						color="primary"
						(click)="toggleView()"
					>
						<mat-icon inline>edit</mat-icon>
					</a>
				}	
				
			</div>
		}
		@if (editView) {
			<green-input-auto-complete
				class="edit-view"
				[values]="values ?? []"
				[mode]="mode"
				[type]="type"
				[listValues]="listValues"
				(onChangedValues)="valuesChanged($event)"
				(editView)="editView = false" />
		}
	</div>
 }


<!-- MENU ITEM -->
 @if (mode === 'menuItem') {
	<a
		[ngClass]="{'pointer-events-none': disabled, 'opacity-50': disabled}"
		mat-menu-item
		[matMenuTriggerFor]="autocompleteMenu"
		[matBadge]="(values ?? []).length"
		[matBadgeHidden]="(values ?? []).length === 0 || !badge"
	>
		<mat-icon [color]="disabled ? 'disabled' : 'primary'">bookmark_border</mat-icon>
		{{ 'SHARED.TAGS.LABEL' | translate }}
	</a>
 }

<mat-menu
	[ngClass]="{'pointer-events-none': disabled, 'opacity-50': disabled}"
	class="expanded autoComplete"
	#autocompleteMenu="matMenu"
	yPosition="below"
	xPosition="after">
	<div class="input">
		<green-input-auto-complete
			[ngClass]="{'pointer-events-none': disabled, 'opacity-50': disabled}"
			[values]="values ?? []"
			[mode]="mode"
			[type]="type"
			[listValues]="listValues"
			(onChangedValues)="valuesChanged($event)" />
	</div>
</mat-menu>
