import { isEquals } from "./is-equals.fn"

export const areObjectsEqual = (objA: any, objB: any): boolean => {
	const nullValues = [null, undefined];

	if (nullValues.includes(objA) || nullValues.includes(objB)) {
		return isEquals(objA, objB);
	}

	const keys = Object.keys(objA);

	if (keys.length !== Object.keys(objB).length) {
		return false;
	}

	return !keys.some(key => objA[key] !== objB[key]);
}
