<mat-card
	[title]="title ?? '' | translate"
	class="mx-auto w-fit px-5 max-w-full">

	@if(title || subtitle) {
	
		<mat-card-header class="text-primary !pt-8 !pb-2">

			@if (!!title) {
				<mat-card-title class="text-center !text-2xl">
					{{ title | translate }}
				</mat-card-title>
			}
			@if (!!subtitle) {
				<mat-card-subtitle class="text-center text-balance !text-lg">
					{{ subtitle | translate }}
				</mat-card-subtitle>
			}
		</mat-card-header>
	}

	<mat-card-content
		class="relative !p-4 !flex flex-col items-center justify-start"
		style="min-width: 200px; min-height: 50px">
		<ng-content></ng-content>
	</mat-card-content>

	@if (!!cardFooter) {
		<mat-card-actions class="justify-evenly !px-2 !pb-8">
			<ng-container [ngTemplateOutlet]="cardFooter"></ng-container>
		</mat-card-actions>
	}
</mat-card>
