import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError,  map, switchMap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {NotificationService} from '@core/modules/notification';
import {AppState} from 'src/app/store/app.state';
import {Store, select} from '@ngrx/store';
import { CsmActions } from './csm.actions';
import { CsmService } from 'src/app/views/admin-board/services/csm.service';
import {areObjectsEqual} from '@core/helpers/fn/are-objects-equal.fn';
import { filterSelector, isLoadedSelector } from './csm.selector';

@Injectable()
export class CsmEffects {
	constructor(
		private actions$: Actions,
		private csmService: CsmService,
		private store: Store<AppState>,
		private readonly notifier: NotificationService
	) {}

	getCsmAccounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CsmActions.getCsmAccounts),
			withLatestFrom(
				this.store.pipe(select(isLoadedSelector)),
				this.store.pipe(select(filterSelector))
			),
			switchMap(([{params}, loaded, filter]) => {
				if (areObjectsEqual(filter, params) && loaded) {
					return EMPTY;
				}
				return this.csmService.getLastAccountsForCsm(params).pipe(
					map(res => CsmActions.getCsmAccountsSuccess({csmAccounts: res ?? [], params})),
					catchError(error => { 
						this.notifier.notify('HTTP_ERRORS.GENERIC');
						return of(CsmActions.getCsmAccountsFailure({error}))
					})
				);
			})
		)
	);
}
