import {Component, OnDestroy} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {CookieStore} from '@core/modules/storage';
import {AuthService} from '@core/modules/auth';
import {Theme} from '@shared/modules/users/types/user';


@Component({
	selector: 'green-root',
	template: ` <app-toaster /> <router-outlet /> `,
})
export class AppComponent implements OnDestroy {
	private destroyed$ = new Subject();

	constructor(
		private readonly translator: TranslateService,
		private readonly cookies: CookieStore,
		private readonly auth: AuthService,
	) {
		this.handleUserCurrentLanguage();
		if (this.auth.currentUser()?.theme === Theme.DARK) {
			document.body.classList.add('dark');
		}
	}


	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	private handleUserCurrentLanguage(): void {
		this.translator.addLangs(['en', 'fr', 'zh', 'it', 'es', 'ru', 'ja', 'pt', 'de']);
		const browserLang = this.translator.getBrowserLang();
		const lang = this.auth.currentUser()?.lang;
		if (lang) {
			this.translator.use(lang.toLowerCase()).pipe(takeUntil(this.destroyed$));
		} else if (browserLang) {
			this.translator.use(browserLang).pipe(takeUntil(this.destroyed$));
		}
	}
}
