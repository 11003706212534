import {Location, NgFor, NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {Router} from '@angular/router';
import {CookieStore} from '@core/modules/storage';
import {TruncatedTextComponent} from '@shared/components/ui';
import {FolderViewDto} from '@shared/modules/library/modules/folders/types/folder-view.dto';
import {TranslatePipe} from '@shared/pipes';

@Component({
	standalone: true,
	selector: 'green-bread-crumb',
	imports: [
		NgFor,
		NgIf,
		MatButtonModule,
		MatIconModule,
		TranslatePipe,
		TruncatedTextComponent,
	],
	template: `@if (showLibrary) {
					<span class="inline-flex">
						<a mat-button (click)="setCurrentFolder(null)">
							<mat-icon>home</mat-icon>

							@if (!data.length) {
								<p>{{ 'VIEWS.USER_BOARD.LIBRARY.HEADER.TITLE' | translate }}</p>
							}
						</a>
					</span>
				}
				@for (bc of data; track bc.name) {
					<span class="inline-flex">
						<a mat-button (click)="setCurrentFolder(bc)">
							<mat-icon style="margin-inline-start: -14px"
								>keyboard_arrow_right</mat-icon
							>
							<green-truncated-text
								[value]="bc.name"
								[maxLength]="10"
								class="text-sm" />
						</a>
					</span>
				}
		`,
})
export class BreadCrumbComponent {
	@Input() showLibrary: boolean = true;
	@Input() isAdmin: boolean;
	@Input() data: any;

	constructor(
		private readonly cookies: CookieStore,
		private readonly location: Location,
		private readonly router: Router
	) {}

	setCurrentFolder(folder: FolderViewDto | null): void {
		this.cookies.set(
			'current-folder',
			JSON.stringify({...folder, subFolder: []})
		);
		this.isAdmin
			? this.location.back()
			: this.router.navigate(['/app/library/']);
	}
}
