import {NgModule} from '@angular/core';

import {APP_ROUTES} from './app.routes';
import {AppComponent} from './app.component';
import {CORE_MODULES} from './core';
import {RouterModule} from '@angular/router';
import {ToasterComponent} from '@core/modules/notification/components/toast.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CsmEffects } from './store/csm';
import { csmAccountsReducer } from './store/csm/csm.reducer';
import { CsmService } from './views/admin-board/services/csm.service';


@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		...CORE_MODULES,
		RouterModule.forRoot(APP_ROUTES),
		ToasterComponent,
		StoreModule.forRoot({
			csmAccounts: csmAccountsReducer
		}),
		EffectsModule.forRoot([CsmEffects]),
			// StoreDevtoolsModule.instrument({
		//   maxAge: 25, // Retains last 25 states
		//   autoPause: true, // Pauses recording actions and state changes when the extension window is not open
		// }),
	],
	providers: [
		CsmService,
	]
})
export class AppModule {}
