import {NgClass, NgFor, NgIf} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {ThemePalette} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {TranslatePipe} from '@shared/pipes';
import {DurationPipe, FileSizePipe} from '@shared/pipes';

export type BannerInformation = {
	name: string;
	value?: any;
	icon: string;
	type?: 'size' | 'duration' | 'date';
	color: ThemePalette;
};

@Component({
	standalone: true,
	selector: 'green-banner',
	imports: [
		NgFor,
		NgIf,
		NgClass,
		MatCardModule,
		MatProgressBarModule,
		MatIconModule,
		DurationPipe,
		FileSizePipe,
		TranslatePipe,
	],
	template: `<mat-card
		class="flex-1 mat-elevation-z4 !flex !flex-row flex-wrap items-center justify-between">

		@for (info of infos; track info.name) {
			<div class="flex items-center justify-center flex-1 h-full w-56 py-2">
				<mat-icon class="sm" [color]="info.color">{{ info.icon }}</mat-icon>
				<div class="ml-4">
					<h4 class="italic mb-2 text-sm disabled">
						{{ info.name | translate }}
					</h4>
					<strong [class]="'is-' + info.color" class="text-3xl">
						
						@if (!info.type) {
							<span>{{ info.value ?? '--' }}</span>
						}
						@if (info.type === 'duration') {
							<span>{{ info.value ?? 0 | duration }}</span>
						}
						@if (info.type === 'size') {
							<span>{{ info.value ?? 0 | filesize }}</span>
						}
					</strong>
				</div>
			</div>
		}
		
		@if (!infos) {
			<mat-progress-bar mode="indeterminate" color="accent" />
		}
	</mat-card>`,
})
export class BannerComponent implements OnChanges {
	@Input({required: true}) infos: BannerInformation[];

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.infos) {
			this.infos = changes.infos.currentValue;
		}
	}
}
