import {NgFor, NgIf} from '@angular/common';
import {Component, Inject, OnDestroy} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {
	MatDialogRef,
	MAT_DIALOG_DATA,
	MatDialogModule,
} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ErrorMessages, Patterns} from '@core/modules/forms';
import {TranslatePipe} from '@shared/pipes';
import {PasswordOnlyForm} from '@shared/modules/users/models/password-form';
import {MatInputModule} from '@angular/material/input';

/**
 * Represents Password confirmation Dialog states.
 */
export interface ConfirmationPassData {
	password: string;
	message: string;
	confirmed: boolean;
}

/**
 * Dialog used to confirm action by user requesting a password to continue
 */
@Component({
	standalone: true,
	selector: 'green-confirmation-pass',
	imports: [
		NgIf,
		NgFor,
		MatDialogModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		TranslatePipe,
	],
	template: `<h2 mat-dialog-title class="centered is-primary">
			{{ 'SHARED.CONFIRMATION_NEEDED' | translate }}
		</h2>
		<mat-dialog-content mat-dialog-content>
			<p class="legend">{{ message | translate }}</p>
			<form class="large mt-4" [formGroup]="confirmationForm">
				<mat-form-field>
					<mat-label>{{ 'FORMS.USER.PASSWORD.LABEL' | translate }}</mat-label>
					<input
						matInput
						formControlName="password"
						placeholder="******"
						maxlength="20"
						[type]="this.hide ? 'password' : 'text'"
						required />
					<mat-icon matSuffix (click)="hide = !hide" class="xs">{{
						this.hide ? 'visibility_off' : 'visibility'
					}}</mat-icon>
					
					@for (validation of errorMsg.password; track validation.message) {
						<mat-error>

							@if (
								patterns.isValid(
									this.confirmationForm.get('password'),
									validation
								)
							) {
								<mat-error>{{ validation.message | translate }}</mat-error>
							}	
						</mat-error>
					} 	
				</mat-form-field>
			</form>
		</mat-dialog-content>
		<mat-dialog-actions
			mat-dialog-actions
			class="spaced flex justify-evenly items-center">
			<button color="accent" mat-stroked-button (click)="onCancel()">
				{{ 'BUTTONS.CANCEL' | translate }}
			</button>
			<button
				color="primary"
				mat-raised-button
				[disabled]="this.confirmationForm.get('password')?.invalid"
				[mat-dialog-close]="onConfirm()">
				{{ 'BUTTONS.CONFIRM' | translate }}
			</button>
		</mat-dialog-actions> `,
})
export class ConfirmationPassComponent implements OnDestroy {
	hide = true;
	readonly confirmationForm: FormGroup = PasswordOnlyForm;
	readonly errorMsg = ErrorMessages.user;
	readonly patterns = Patterns;

	message: string;

	constructor(
		private dialogRef: MatDialogRef<ConfirmationPassComponent>,
		@Inject(MAT_DIALOG_DATA) private data: ConfirmationPassData
	) {
		this.message = this.data.message;
	}

	ngOnDestroy(): void {
		this.confirmationForm.reset();
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	onConfirm(): ConfirmationPassData {
		this.data.password = this.confirmationForm.get('password')?.value;
		this.data.confirmed = true;
		return this.data;
	}
}
