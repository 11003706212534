<ng-template #content>
	<ng-content></ng-content>

	@if (label) {
		{{ label | translate }}
	}
</ng-template>

@if (href) {

	@switch(type) {

		@case('raised') {
			<a
				[routerLink]="href"
				[fragment]="fragment"
				[queryParams]="params"
				mat-raised-button
				[color]="color"
				[disabled]="disabled">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		}
		@case('stroked') {
			<a
				[routerLink]="href"
				[queryParams]="params"
				[fragment]="fragment"
				mat-stroked-button
				[color]="color"
				[disabled]="disabled">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		}
		@case('flat') {
			<a
				[routerLink]="href"
				[queryParams]="params"
				[fragment]="fragment"
				mat-flat-button
				[color]="color"
				[disabled]="disabled">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		}
		@case('icon') {
			<a
				[routerLink]="href"
				[queryParams]="params"
				[fragment]="fragment"
				mat-icon-button
				[color]="color"
				[disabled]="disabled">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		}
		@case('menu-item') {
			<a
				[routerLink]="href"
				[queryParams]="params"
				[fragment]="fragment"
				mat-menu-item
				[class]="'text-' + color"
				[disabled]="disabled">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		}
		@default {
			<a
				mat-button
				[routerLink]="href"
				[queryParams]="params"
				[fragment]="fragment"
				[color]="color"
				[disabled]="disabled">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		}
	}
}
@else {

		@switch(type) {

			@case('raised') {
				<button
					mat-raised-button
					[color]="color"
					[disabled]="disabled">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</button>
			}
			@case('stroked') {
				<button
					mat-stroked-button
					[color]="color"
					[disabled]="disabled">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</button>
			}
			@case('flat') {
				<button
					mat-flat-button
					[color]="color"
					[disabled]="disabled">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</button>
			}
			@case('icon') {
				<button
					mat-icon-button
					[color]="color"
					[disabled]="disabled">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</button>
			}
			@case('menu-item') {
				<button
					mat-menu-item
					[class]="'text-' + color"
					[disabled]="disabled">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</button>
			}
			@default {
				<button mat-button [color]="color" [disabled]="disabled">
					<ng-container *ngTemplateOutlet="content"></ng-container>
				</button>
			}
		}
}
