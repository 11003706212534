import {NgIf} from '@angular/common';
import {
	Component,
	HostBinding,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TruncateTextPipe} from '@shared/pipes';

@Component({
	standalone: true,
	imports: [NgIf, MatTooltipModule, TruncateTextPipe],
	selector: 'green-truncated-text',
	template: `
		@if (value) {
			<span
				class="inline-block break-all"
				[matTooltip]="value"
				[matTooltipDisabled]="value.length <= maxLength"
				[innerHTML]="value | truncateText : maxLength">
			</span> 
		}`,
})
export class TruncatedTextComponent implements OnChanges {
	@Input({required: true}) value?: string | null;
	@Input() maxLength = 20;

	@HostBinding('class') class = 'inline-block max-w-full min-w-fit leading-7';
	@HostBinding('style') style = 'font-size: 1em';

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value) {
			this.value = changes.value.currentValue;
		} else if (changes.maxLength) {
			this.maxLength = changes.maxLength.currentValue;
		}
	}
}
